<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'hotelHomestay',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/goods/farmRoom/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'productName',
          type: 'input'
        },
        {
          name: '店铺名称',
          key: 'mainBodyName',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'productName',
          title: '名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length
        },
        {
          dataIndex: 'linkShopName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.linkShopName.length - b.linkShopName.length
        },
        {
          dataIndex: 'hotelName',
          title: '酒店',
          align: 'left',
          sorter: (a, b) => a.hotelName.length - b.hotelName.length
        },
        {
          dataIndex: 'roomName',
          title: '房间',
          align: 'left',
          sorter: (a, b) => a.roomName.length - b.roomName.length
        },
        // {
        //   dataIndex: 'salePrice',
        //   title: '现价',
        //   align: 'left',
        //   customRender: function(text, records) {
        //     return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
        //   },
        //   sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length
        // },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: '60px',
          type: 'badge',
          align: 'center',
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/goods/farmRoom/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '日历价格',
                onClick: () => this.$router.push(`/commodityManagement/calendar?productId=${records.id}&productType=2`)
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/hotelHomestayDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmRoom?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.del.call(this, {
                  url: '/goods/farmRoom/deleteBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmRoom/upBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmRoom/downBatch',
                  params: { idList }
                })
              }
            }
          ]
        },
        {
          name: '酒店民宿管理',
          type: 'primary',
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayManage')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
